import { Injectable, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import localeTh from '@angular/common/locales/th';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NativeDateAdapter } from '@angular/material/core';


// Material Module
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';

registerLocaleData(localeTh)
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  override format(value: Date): string {
    const monthNamesThai = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
      "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค"];
    if (!value) { return '-'; }
    value.setHours(7)
    const date = new Intl.DateTimeFormat('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: "GMT"
    }).format(new Date(value));
    // console.log(date)
    return date;
  }
}

export class AppDateAdapter2 extends NativeDateAdapter {
  override format(value: Date): string {
    const monthNamesThai = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
      "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค"];
    if (!value) { return '-'; }
    value.setHours(7)
    const date = new Intl.DateTimeFormat('th-TH', {
      year: 'numeric',
      month: 'long',
      // day: 'numeric',
      timeZone: "GMT"
    }).format(new Date(value));
    // console.log(date)
    return date;
  }
}

export class AppDateAdapterOnlyDate extends NativeDateAdapter {
  override format(value: Date): string {
    const monthNamesThai = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
      "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค"];
    if (!value) { return '-'; }
    value.setHours(7)
    const date = new Intl.DateTimeFormat('th-TH', {
      // year: 'numeric',
      // month: 'long',
      day: 'numeric',
      timeZone: "GMT"
    }).format(new Date(value));
    // console.log(date)
    return date;
  }
}




export const PICK_FORMATS = {
  parse: {
    dateInput: {
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { day: 'numeric', year: 'numeric', month: 'long' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
//  format for Moment
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'DD-MMM-YYYY',
    dateA11yLabel: 'DD-MMM-YYYY',
    monthYearA11yLabel: 'DD-MMM-YYYY',
  },
};


export const MY_FORMATS2 = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


export const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
]


export const mat = [
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  // MatExpansionModule,
  // MatToolbarModule,
  // MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  // MatGridListModule,
  // MatTableModule,
  MatIconModule,
  // MatChipsModule,
  // MatCheckboxModule,
  // MatStepperModule,
  // MatRadioModule,
  // MatSelectModule,
  // MatDatepickerModule,
  MatAutocompleteModule,
  // MatSnackBarModule,
  // MatMenuModule,
  // MatNativeDateModule,
  // MatProgressBarModule
]

const pipe = [
  // DemoPipe,
  // ThaiDatePipe,/
] as const

const directive = [
  // DemoDirective
] as const

export const SHARED = [
  ...mat,
  ...pipe,
  ...directive,
  ...MODULES
] as const



@NgModule({
  declarations: [
    // DemoDirective,
    // DemoPipe,
    // ThaiDatePipe
  ],
  imports: [
    ...mat,
    ...MODULES
  ],
  exports: [
    ...mat,
    ...SHARED,
  ],
  providers: [
  ]
})
export class SharedModule { }




